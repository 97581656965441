<template>
  <v-card
    elevation="0"
    max-width="1010"
    rounded="xl"
    class="mx-auto pa-sm-8 pa-4 login-confirm width100"
  >
    <v-btn class="btn-back px-2" text @click="back">
      <v-icon small color="gray" class="pt-1">mdi-chevron-left mdi-24px</v-icon>
      <span class="gray--text text-body-1">{{ $t("btn.back") }}</span>
    </v-btn>
    <lottie :img="img" :height="120" :width="120" />
    <div class="f30 arabic-black text-center mb-2 black--text">
      {{ $t("emailConfirm.title") }}
    </div>
    <div class="gray--text text-center mb-8">
      {{ $t("emailConfirm.description") }}
      <span class="primary--text">{{ email }}</span>
    </div>
    <div style="max-width: 380px" class="mx-auto mb-9">
      <v-form @submit.prevent="action" ref="form" lazy-validation>
        <v-btn
          height="64"
          depressed
          type="submit"
          block
          large
          class="primary radius10"
        >
          <span class="text-body-1 font-weight-bold">{{
            $t("btn.confirm")
          }}</span>
        </v-btn>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import Lottie from "@/components/lottie.vue";
import img from "@/assets/icon/confirm.json";
import jwt_decode from "jwt-decode";

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      img: img,
      error: [],
    };
  },
  mounted() {
    if (jwt_decode(this.$route.query.t).exp < Date.now() / 1000) {
      this.notifi("expired");
    }
    sessionStorage.setItem("accessToken", this.$route.query.t);
  },
  methods: {
    notifi(btn) {
      if (btn == "invalid") {
        this.$notify({
          title: this.$t("alert.tokenInvalidTitle"),
          message: this.$t("alert.tokenInvalid"),
          type: "error",
          icon: "mdi-alert-outline",
          horizontalAlign: "center",
          verticalAlign: "top",
        });
      } else if (btn == "expired") {
        this.$notify({
          title: this.$t("alert.tokenExpiredTitle"),
          message: this.$t("alert.tokenExpired"),
          type: "error",
          icon: "mdi-alert-outline",
          horizontalAlign: "center",
          verticalAlign: "top",
        });
      } else if (btn == "already") {
        this.$notify({
          title: "",
          message: this.$t("alert.tokenAlready"),
          type: "error",
          icon: "mdi-alert-outline",
          horizontalAlign: "center",
          verticalAlign: "top",
        });
      }
    },
    back() {
      this.$router.push("/sign-in");
    },
    async action() {
      this.error = [];
      const data = new Object();
      data.token = this.$route.query.t;
      await this.$store
        .dispatch("confirmEmail", data)
        .then((res) => {
          if (res.data.auth_token) {
            sessionStorage.setItem("accessToken", res.data.auth_token);
            this.$router.push("/sign-in/confirm");
          } else {
            res.data.access_token
              ? sessionStorage.setItem("accessToken", res.data.access_token)
              : "";
            setTimeout(() => {
              this.$router.push("/");
            }, 500);
          }
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.error.find((item) => item == "token__invalid")
            ? this.notifi("invalid")
            : "";
          this.error.find((item) => item == "token__expired")
            ? this.notifi("expired")
            : "";
          this.error.find((item) => item == "token__already_used")
            ? this.notifi("already")
            : "";
        });
    },
  },
  computed: {
    email() {
      return jwt_decode(this.$route.query.t).extra.email;
    },
  },
};
</script>
